import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/Migration/';

class AdminMigrationService {
  startMigration() {
    return ApiService.get(`${API_URL}StartMigration`);
  }
}

export default new AdminMigrationService();
