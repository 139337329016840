














import Vue from 'vue';
import AdminMigrateDataService from '@/services/admin/AdminMigrateDataService';
import { MetaInfo } from 'vue-meta';
import { mapState } from 'vuex';

export default Vue.extend({
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminMigrateData.meta.title').toString(),
    };
  },
  data() {
    return {
      loading: false,
      message: '',
    };
  },
  computed: {
    ...mapState('MaintainanceStoreModule', ['seedProgressText', 'seedProgressStep', 'seedProgressTotalSteps']),

    percentage(): number {
      if (this.seedProgressTotalSteps == 0) {
        return 0;
      } else {
        return (this.seedProgressStep / this.seedProgressTotalSteps) * 100;
      }
    },
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      return AdminMigrateDataService.startMigration().then(
        () => {
          this.message = '';
        },
        (error) => {
          this.loading = false;
          this.message = error;
        }
      );
    },

    setBreadcrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminMigrateData.breadcrumb.last' },
      ]);
    },
  },
  mounted() {
    this.setBreadcrumb();
  },
});
